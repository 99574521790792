import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const TechnicalManagement = () => {
    return (
        <Layout>
            <Seo title="Technical & Crew Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Technical & Crew Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        At Delta, our constant objective is to provide Owners with safe, cost-efficient, and
                                        environmentally-sound services meeting agreed specifications for Quality Management, focusing
                                        on continuously minimizing process losses and downtime and achieving zero failure.
                                    </p>
                                    <div className="mb-3">
                                        <h4>Operations supervision and monitoring</h4>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item gray-bg">
                                                Responsible for maintaining the company's safety standards on vessels, and for
                                                coordinating, assisting the Master on the implementation and maintenance of the
                                                company's Shipboard Safety and Environment Protection Management System.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Day-to-Day monitoring of Ship Operations to ensure that they are executed in accordance
                                                with the "Safety Health Environment & Quality", our Policies and Best Industry
                                                Practices.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Ensure vessel always meets Owners and Charterers' technical, operational, and
                                                commercial requirements for the trade
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mb-3">
                                        <h4>Safety standards & compliance</h4>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item gray-bg">
                                                Implementing Flag State and Classification requirements, monitoring and ensuring
                                                Inspections/Surveys are carried out in a timely manner.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Ensure vessels are prepared for PSC inspections, monitor, and respond to PSC reports.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Ensure vessels are provided crew as per the vessel and trade requirements.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Train crew to permanently adhere to Safe working practices, & compliance with MARPOL &
                                                other statutory rules and regulations & provide adequate training tools.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Perform role of CSO to ensure adequate security levels and interface is always
                                                maintained between ship and shore.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Liaise with relevant authorities for Safety, Insurance and QMS matters including
                                                performance of QMS audits.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Assist the Casualty Contingency team in case of an emergency.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Implement corrective actions as and when necessary and control their effectiveness.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mb-3">
                                        <h4>Budget, preventive maintenance & condition monitoring</h4>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item gray-bg">
                                                Establish an Annual Operational Budget and Master Plan and its monitoring throughout
                                                the year.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Establish a Preventive Maintenance Plan and the execution of the maintenance activities
                                                when required.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Implement corrective actions as and when necessary and verify their effectiveness.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Responding to day to day un-planned maintenance requirements of the vessels.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Conduct of dry-dockings and repairs whenever necessary.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Introduce condition monitoring tools to achieve improved cost-efficiency.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mb-3">
                                        <h4>Procurement & logistics</h4>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item gray-bg">
                                                Select suppliers for performance and cost-efficiency, review, and rate.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Negotiate long-term pricing structures as well as spot supply pricing to achieve
                                                optimal cost savings.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Order stores and spares and provide logistical support to the vessels.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Prepare reports to owners, other external stakeholders as required and maintain
                                                pristine and transparent records.
                                            </li>
                                            <li className="list-group-item gray-bg">
                                                Offer Owners direct access to their Assets records, purchases and provide regularly
                                                relevant benchmark.
                                            </li>
                                            <li className="list-group-item gray-bg">Offer benefit of grouped purchasing to Owners.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    className="mb-3"
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/bulker8.jpg"
                                    aspectRatio={4 / 3}
                                    width={696}
                                />
                                <div className="col-12">
                                    <div className="card-body gray-bg d-flex flex-column mb-3">
                                        <div className="mb-3">
                                            <h4>Crew management</h4>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">
                                                    Recruit properly qualified personnel for service on board vessels and ensure that
                                                    all officers are fully qualified for service on board, liaising with Owners, Flag
                                                    Authorities and Crewing Agents for proper certification and documentation.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Ensure travel arrangements for officers and crew joining or leaving the vessel
                                                    coordinating with vessels Agents and Master to ensure that travel arrangements are
                                                    done efficiently and economically.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Ensure permanent training on board and onshore in a spirit of continuous
                                                    improvement.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Ensure Crew Health, Personal Safety and Welfare matters are handled promptly and
                                                    with care.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Ensure compliance with relevant Labour National and International regulations
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mb-3">
                                            <h4>Insurance services</h4>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item gray-bg">
                                                    Responsible for placing the Hull/Machinery Insurance, P&I and FD&D Cover – in
                                                    coordination with Owners - and for informing Master and other relevant authorities.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Arrange when necessary attendance of Underwriter's survey if an incident or damage
                                                    may result in a claim, i.e. pollution, cargo damage, contact with fixed or floating
                                                    object, hull or machinery casualty, etc. and then inform, coordinate and report
                                                    with various departments and Owners.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Handle all correspondence and settle the claim with the Underwriters.
                                                </li>
                                                <li className="list-group-item gray-bg">
                                                    Responsible for informing, coordinating and reporting to P&I Club, and Owners on
                                                    P&I related incidents.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <StaticImage
                                        className="mb-3"
                                        transformOptions={{ cropFocus: "attention" }}
                                        alt="Sales team"
                                        src="../../../images/other/bulker4.jpg"
                                        aspectRatio={4 / 3}
                                        width={696}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default TechnicalManagement
